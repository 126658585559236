/* src/App.css */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.url-input {
  padding: 10px;
  font-size: 16px;
  width: 300px;
  margin-bottom: 20px;
}

.scan-button, .new-scan-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #61dafb;
  border: none;
  cursor: pointer;
  color: #282c34;
  /* margin-top: 10px; */
}

.scan-button:hover, .new-scan-button:hover {
  background-color: #21a1f1;
}


/* Add a black background color to the top navigation */
.topnav {
  background-color: #333;
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav button {
float: left;
color: #f2f2f2;
text-align: center;
padding: 14px 16px;
text-decoration: none;
font-size: 17px;
}

/* Change the color of links on hover */
.topnav button:hover {
background-color: #ddd;
color: black;
}

/* Add a color to the active/current link */
.topnav button.active {
background-color: #04AA6D;
color: white;
}

/* Right-aligned section inside the top navigation */
.topnav-right {
float: right;
}





.result-display {
  margin-top: 20px;
}

.result-display p {
  margin: 10px 0;
}

.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.spinner {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.tabs {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.tab {
  background-color: #f1f1f1;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.tab:hover {
  background-color: #ddd;
}

.tab.active {
  background-color: #007bff;
  color: white;
}

.tab-content {
  margin-top: 20px;
}

.display-table {
  table-layout: fixed;
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
}

.display-table td {
  padding: 10px;
  border: 1px solid #ddd;
  word-wrap: break-word;         /* All browsers since IE 5.5+ */
  overflow-wrap: break-word;     /* Renamed property in CSS3 draft spec */
}

.nice-link {
  color:  #007bff;
  font-weight: 600;
  text-decoration: underline;
}

.sms-content {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.phone-number {
  font-weight: bold;
  color: #61dafb;
}

.home-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #61dafb;
  color: #282c34;
  text-decoration: none;
  border-radius: 4px;
  font-size: 16px;
}

.home-link:hover {
  background-color: #21a1f1;
}
